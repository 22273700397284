/**
 * This file is used as the Prismic preview resolver page. The preview resolver
 * page routes editors from the Prismic writing room to a previewed document
 * within your app. For example, if an editor clicks the preview button for a
 * blog post in the writing room, they will land on the preview resolver page
 * within your app, which then redirects them to the blog post with previewed
 * content.
 *
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews#withprismicpreviewresolver
 */

import * as React from "react"
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews"

const PreviewPage = () => {
  return (
    <div style={{ display: "grid", placeItems: "center", minHeight: "100vh" }}>
      <h1>Loading preview…</h1>
    </div>
  )
}

export default withPrismicPreviewResolver(PreviewPage)
